export function dateFunction() {
  const lastToday = (date?: Date): Date => {
    const today = new Date();
    if (date) return new Date(date.setHours(23, 59));

    return new Date(today.setHours(23, 59));
  };

  const getLastWeeksDate = (date: Date): Date => new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);

  const getLastMonthDate = (date: Date): Date => new Date(date.setMonth(date.getMonth() - 1));

  const getLast3MonthsDate = (date: Date): Date => new Date(date.setMonth(date.getMonth() - 3));

  return { getLastWeeksDate, getLastMonthDate, getLast3MonthsDate, lastToday };
}

export const getTomorrowDate = (date?: Date) => {
  const tomorrow = new Date();
  if (date) {
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
  }
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getYesterdayDate = (date?: Date) => {
  const yesterday = new Date();
  if (date) {
    yesterday.setDate(date.getDate() - 1);
    return yesterday;
  }

  yesterday.setDate(yesterday.getDate() - 1);

  return yesterday;
};
