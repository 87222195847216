import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createClient from './apolloClient';

// Setup interationalisation
import { IntlProvider } from 'react-intl';

import messages from './locales/message';

const supportedLanguages = ['fr', 'en'];

let locale: string = (navigator.languages && navigator.languages[0]) || navigator.language || 'en';

if (!supportedLanguages.find((l) => l === locale)) {
  locale = 'en';
}

if (localStorage.getItem('language')) {
  locale = localStorage.getItem('language') || '';
}

const token = localStorage.getItem('access_token');
const client = createClient(token || '');

ReactDOM.render(
  <ApolloProvider client={client}>
    <IntlProvider locale={locale} messages={(messages as any)[locale]}>
      <App />
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
