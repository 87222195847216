import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { createClient as createClientWs } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { API_URL, SUBSCRIPTION_URL } from './config';
import { encodeTextBody, shouldEncode } from './utils';

function getHttpLink(token: string) {
  const customFetch = (uri: RequestInfo, options: RequestInit) => {
    let headers: HeadersInit & {
      authorization?: string;
      'content-type'?: string;
      'Content-Transfer-Encoding'?: string;
    } = {
      ...options.headers,
    };

    if (token) headers['authorization'] = `Bearer ${token}`;
    // Include current company slug to the header

    const isEncoding = shouldEncode(uri, options);

    if (isEncoding) {
      headers['content-type'] = 'text/plain; charset=UTF-8';
      headers['Content-Transfer-Encoding'] = 'base64';
      if (options.body)
        return fetch(uri, { ...options, headers, body: encodeTextBody(options.body.toString()) });
    }
    return fetch(uri, { ...options, headers });
  };

  return createHttpLink({
    uri: API_URL,
    fetch: customFetch,
  });
}
const createClient = (token: string) => {
  // const httpLink = createHttpLink({
  //   uri: API_URL,
  // });

  const wsLink = new GraphQLWsLink(
    createClientWs({
      url: SUBSCRIPTION_URL,
      connectionParams: {
        authorization: token,
      },
    }),
  );

  // const authLink = setContext((_, { headers }) => {
  //   // get the authentication token from local storage if it exists
  //   // const token = localStorage.getItem('token');
  //   // return the headers to the context so httpLink can read them
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `${token}` : '',
  //     },
  //   };
  // });
  const httpLink = getHttpLink(token);
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

export default createClient;
