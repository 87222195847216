import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#263238',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#64dd17',
      contrastText: '#000',
    },
  },
});

export default theme;
