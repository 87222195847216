import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { API_URL } from 'config';
import { GET_S3_SIGNED_URL } from 'graphql/file/query';
import { GetS3SignedURL } from 'graphql/file/types/GetS3SignedURL';
import { Exact } from 'types';

export const getS3SignedUrl = async (
  file?: File,
  repo?: string,
  onLoad?: React.Dispatch<React.SetStateAction<boolean>>,
  cancelTokenSource?: CancelTokenSource,
) => {
  const token = localStorage.getItem('access_token');

  const uploadS3Options: AxiosRequestConfig = {
    headers: {
      'Content-Type': file!.type,
    },

    onUploadProgress: () => {
      onLoad?.(true);
    },

    cancelToken: cancelTokenSource?.token,
  };

  const extension = file!.type?.split('/')?.[1];
  const size = file?.size;
  const type = file?.type;
  const name = file!.name.split(`.${extension}`)?.[0] ?? '';

  const variables: Exact<{
    name: string;
    extension: string;
    repo?: string;
  }> = {
    name,
    extension,
    repo,
  };

  const res = await axios.post<AxiosResponse<GetS3SignedURL>>(
    API_URL,
    {
      operationName: 'GetS3SignedURL',
      query: GET_S3_SIGNED_URL.loc!.source.body,
      variables,
    },
    {
      headers: {
        authorization: token ?? '',
      },
    },
  );

  return axios.put(res.data.data.localFileSignedUrl?.url ?? '', file, uploadS3Options).then(() => {
    return {
      key: res.data.data.localFileSignedUrl?.key ?? '',
      url: res.data.data.localFileSignedUrl?.url?.split('?')[0] ?? '',
      name,
      size,
      type,
    };
  });
};
