export default {
  fr: {
    'auth.login': 'Connexion',
    'auth.email': 'Email',
    'auth.password': 'Mot de passe',
    'professional.status': 'Statut Professionel',
  },
  en: {
    'auth.login': 'Connection',
    'auth.email': 'Email',
    'auth.password': 'Password',
    'professional.status': 'Professional Status',
  },
};
