import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import theme from './theme';
import { PrivateRoute } from './components/PrivateRoute';

const SignIn = Loadable({
  loader: () => import('./components/Authentication/Signin/Signin'),
  loading: () => <div>Loading...</div>,
});

const Dashboard = Loadable({
  loader: () => import('./components/Dashboard/Dashboard'),
  loading: () => <div>Loading...</div>,
});

const App = () => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={MomentUtils}>
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/login" component={SignIn} />
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </LocalizationProvider>
  </ThemeProvider>
);

export default App;
