import { gql } from '@apollo/client';

export const GET_S3_SIGNED_URL = gql`
  query GetS3SignedURL(
    $name: String!
    $extension: String!
    $repo: String
    $expires: Int
    $s3Operation: S3Operation
  ) {
    localFileSignedUrl(
      name: $name
      extension: $extension
      repo: $repo
      expires: $expires
      s3Operation: $s3Operation
    ) {
      url
      key
    }
  }
`;

export const GET_ONE_FILE = gql`
  query getFile($id: ID!) {
    data: File(id: $id) {
      id
      publicUrl
    }
  }
`;
