export * from './Validator';
export * from './formatFileSize';
export * from './encode';
export * from './exportToCsv';
export * from './getS3signedUrl';
export * from './dateFunction';

export const toCapitalCase = (inputString?: string) => {
  if (!inputString) return inputString;

  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};
